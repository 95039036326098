import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { DownloadApp, NewsletterSignup } from "../../components/PromoBlade"
import * as styles from "./PromoSection.module.scss"

export interface PromoSectionProps {
  /**
   * Promo blade type:
   * - "Download the app" with custom copy and links.
   * - "Signup to the newsletter"
   */
  type: "download-app" | "newsletter-signup"

  /**
   * HTML copy to display in the right side of the promo blade.
   */
  html: string
}

/**
 * Display <PromoBlade /> components of various types.
 */
const PromoSection = ({ type, html }: PromoSectionProps) => {
  if (type === "download-app") {
    return (
      <DownloadApp className={styles.downloadApp}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </DownloadApp>
    )
  } else if (type === "newsletter-signup") {
    return <NewsletterSignup />
  }

  return null
}

export default PromoSection
