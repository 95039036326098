import remarkHtml from "remark-html"
import remarkParse from "remark-parse"
import { unified } from "unified"

/**
 * Convert markdown to HTML using remark.
 *
 * The conversion process is synchronous making it suitable for SSG.
 *
 * @param markdown Markdown.
 * @returns HTML.
 */
export const markdownToHtml = (markdown: string) => {
  const result = unified()
    .use(remarkParse)
    .use(remarkHtml)
    .processSync(markdown)
    .toString()
  return result
}
