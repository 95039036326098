import { PageProps, graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import BackgroundGradients from "../components/BackgroundGradients"
import Layout from "../components/Layout/Layout"
import { PostHeader, RelatedPosts } from "../components/Post"
import Seo from "../components/Seo"
import { DataPost } from "../contracts"
import { capitalizeFirstLetter } from "../utils"
import * as styles from "./ParentingPost.module.scss"
import SectionBuilder from "./sections/SectionBuilder"

interface DataProps {
  markdownRemark: DataPost
}

const ParentingPost = ({
  data: { markdownRemark: post },
}: PageProps<DataProps>) => {
  const {
    id,
    frontmatter: {
      title,
      description,
      date,
      tags: rawTags,
      author,
      sections,
      thumbnail,
      shareThumbnail,
    },
  } = post
  const tags = rawTags.map(tag => capitalizeFirstLetter(tag))
  const thumbnailImage = getImage(thumbnail)
  const shareThumbnailSrc = shareThumbnail ? getSrc(shareThumbnail) : undefined

  return (
    <Layout logoColor="light" menuColor="dark">
      <Seo title={title} description={description} image={shareThumbnailSrc} />

      <article className={styles.post}>
        <Container fluid className="gx-0 position-relative">
          <Row className="gx-0">
            <Col md={6}>
              {thumbnailImage ? (
                <div className={styles.postImageWrapper}>
                  <GatsbyImage
                    image={thumbnailImage}
                    alt=""
                    objectFit="cover"
                    loading="eager"
                    className={styles.postImage}
                  />
                </div>
              ) : null}
            </Col>

            <Col md={6}>
              <PostHeader
                title={title}
                tags={tags}
                excerpt={description}
                author={author}
                postedAt={date}
                className={styles.postHeader}
              />
            </Col>
          </Row>
        </Container>

        <div id="post-content" className={styles.postContent}>
          {sections.map((section, key) => (
            <SectionBuilder key={key} section={section} />
          ))}
        </div>

        <h3 className="text-center mb-4 mb-md-5">More {tags.join(" & ")}</h3>

        <RelatedPosts tags={rawTags} excludedPosts={[id]} />
      </article>

      <BackgroundGradients
        intensity="high"
        className={styles.mainBackgroundGradients}
      >
        <div className={styles.orange1}></div>
        <div className={styles.purple1}></div>
      </BackgroundGradients>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ParentingPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author
        sections {
          ...ContentSection
          ...PullOutSection
          ...PromoSection
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        shareThumbnail: thumbnail {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`

export default ParentingPost
