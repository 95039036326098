import clsx from "clsx"
import React from "react"
import * as styles from "./ScrollIndicator.module.scss"
import HorizontalScrollIndicatorArrow from "./scroll-indicator-arrow-horizontal.svg"
import VerticalScrollIndicatorArrow from "./scroll-indicator-arrow-vertical.svg"

export interface ScrollIndicatorProps {
  /**
   * Scroll arrow pointing to the right ("horizontal") or down ("vertical").
   */
  direction: "vertical" | "horizontal"

  /**
   * Color of the text and arrow.
   */
  color: "dark" | "light"

  /**
   * The indicator links to this anchor.
   *
   * @example "#post-content"
   */
  href?: string

  /**
   * Grab attention with an animation.
   *
   * @default false
   */
  attention?: boolean

  className?: string

  style?: React.CSSProperties

  /**
   * Click event callback handler.
   */
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void
}

const ScrollIndicator = ({
  direction,
  color,
  href,
  attention = false,
  style,
  className,
  onClick,
}: ScrollIndicatorProps) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={clsx(
        styles.scrollIndicator,
        direction === "horizontal" && styles.horizontal,
        color === "light" && styles.light,
        attention && styles.attention,
        className
      )}
      style={style}
    >
      <span className={styles.label}>Scroll</span>
      {direction === "horizontal" ? (
        <HorizontalScrollIndicatorArrow width={150} height={14} />
      ) : (
        <VerticalScrollIndicatorArrow />
      )}
    </a>
  )
}

export default ScrollIndicator
