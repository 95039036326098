import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import * as styles from "./ContentSection.module.scss"

export interface ContentSectionProps {
  /**
   * HTML content to display.
   */
  html: string
}

/**
 * On desktop - right aligned content area that takes of half the container.
 * https://imgur.com/a/qDG1wO2
 */
const ContentSection = ({ html }: ContentSectionProps) => {
  return (
    <Container fluid="xxl" className={styles.contentSection}>
      <Row>
        <Col md={{ span: 6, offset: 6 }} lg={{ span: 5 }}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Col>
      </Row>
    </Container>
  )
}

export default ContentSection
