import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import BackgroundGradients from "../../components/BackgroundGradients"
import * as styles from "./PullOutSection.module.scss"

export interface PullOutSectionProps {
  /**
   * HTML content to display in the left side (on desktop) of the pull-out.
   */
  html: string

  /**
   * Large image displayed underneath with the post content on desktop.
   */
  image: IGatsbyImageData
}

/**
 * On desktop - left aligned content area and right aligned large image.
 * https://imgur.com/a/MWfugmj
 */
const PullOutSection = ({ html, image }: PullOutSectionProps) => {
  return (
    <div className={styles.pulloutSection}>
      <Container fluid className="gx-0">
        <Row className="align-items-center gx-0 gy-4 gy-md-0">
          <Col md={{ span: 6, offset: 2, order: 2 }}>
            <GatsbyImage image={image} alt="" />
          </Col>

          <Col md={{ span: 3, offset: 1, order: 1 }}>
            <Container fluid className="gx-5 gx-md-0">
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className={styles.copy}
              />
            </Container>
          </Col>
        </Row>
      </Container>

      <BackgroundGradients
        intensity="high"
        className={styles.backgroundGradients}
      >
        <div className={styles.orange1}></div>
        <div className={styles.red1}></div>
      </BackgroundGradients>
    </div>
  )
}

export default PullOutSection
