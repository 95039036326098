import { getImage } from "gatsby-plugin-image"
import { DataPost, Post } from "../contracts"
import { capitalizeFirstLetter } from "./capitalize-first-letter"

/**
 * Converts raw GraphQL post data into the Post shape passed around the app.
 *
 * @param data Raw GraphQL post.
 * @returns Converted Post.
 */
export const convertPostDataToPost = (data: DataPost): Post => {
  const thumbnailImage = getImage(data.frontmatter.thumbnail)

  if (!thumbnailImage) {
    throw new Error("Post missing thumbnail image data.")
  }

  return {
    id: data.id,
    title: data.frontmatter.title,
    tags: data.frontmatter.tags.map(tag => capitalizeFirstLetter(tag)),
    excerpt: data.frontmatter.description,
    thumbnailImage,
    link: data.fields.path,
    author: data.frontmatter.author,
    postedAt: data.frontmatter.date,
  }
}
