import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import { Post } from "../../contracts"
import ScrollIndicator from "../ScrollIndicator"
import * as styles from "./PostHeader.module.scss"

export interface PostHeaderProps
  extends Pick<Post, "title" | "tags" | "excerpt" | "author" | "postedAt"> {
  className?: string
}

const PostHeader = ({
  title,
  tags,
  excerpt,
  author,
  postedAt,
  className,
}: PostHeaderProps) => {
  const tagList = <>{tags.join(", ")}</>

  return (
    <header className={clsx(styles.header, className)}>
      <Container fluid className="gx-5">
        <h5 className={styles.tags}>{tagList}</h5>
        <h4 className="mb-5">{title}</h4>
        <p className={styles.excerpt}>{excerpt}</p>
        <div className={styles.meta}>
          <p className="mb-1">
            <strong>Written by {author}</strong>
          </p>
          <p>{postedAt}</p>
        </div>
      </Container>

      <ScrollIndicator
        href="#post-content"
        direction="vertical"
        color="dark"
        attention
        className={styles.scrollCta}
      />
    </header>
  )
}

export default PostHeader
