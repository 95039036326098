import clsx from "clsx"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Post } from "../../contracts"
import PostCard from "./PostCard"

export interface PostListProps {
  /**
   * Posts to be displayed as cards. For best results, use a multiple of 3.
   */
  posts: Post[]

  className?: string
}

export const PostList = ({ posts, className }: PostListProps) => {
  return (
    <Container fluid="xxl" className={clsx("gx-0 gx-md-5", className)}>
      <Row xs={1} md={2} xl={3} className="gx-0 gx-md-5 gy-6 gy-md-7">
        {posts.map(post => (
          <Col key={post.id} className="d-flex justify-content-center">
            <PostCard
              title={post.title}
              tags={post.tags}
              excerpt={post.excerpt}
              link={post.link}
              thumbnailImage={post.thumbnailImage}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default PostList
