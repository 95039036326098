import clsx from "clsx"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import useBreakpoint from "use-breakpoint"
import config from "../../config"
import { Post } from "../../contracts"
import * as classes from "./PostCard.module.scss"

export interface PostCardProps
  extends Pick<Post, "title" | "tags" | "excerpt" | "link" | "thumbnailImage"> {
  /**
   * Change to a horizontal layout (image to the left of the content) when reaching
   * this breakpoint.
   */
  horizontalAtBreakpoint?: keyof typeof config.bootstrapBreakpoints

  className?: string
}

/**
 * Display a parenting "blog post" as a card, with a link to the full post route.
 */
const PostCard = ({
  title,
  tags,
  excerpt,
  link,
  thumbnailImage,
  horizontalAtBreakpoint,
  className,
}: PostCardProps) => {
  const { minWidth } = useBreakpoint(config.bootstrapBreakpoints, "xs")

  const horizontalCard =
    horizontalAtBreakpoint &&
    typeof minWidth !== "undefined" &&
    minWidth >= config.bootstrapBreakpoints[horizontalAtBreakpoint]

  const tagList = <>{tags.join(", ")}</>

  return (
    <Card
      bg="transparent"
      className={clsx(
        "border-0",
        classes.card,
        horizontalCard && classes.horizontalCard,
        className
      )}
    >
      <Row className={clsx("align-items-center", !horizontalCard && "gx-0")}>
        <Col md={horizontalCard ? 7 : 12}>
          {/* Not using <Card.Img /> because it doesn't integrate nicely with Gatsby
          image components. */}
          <Link to={link}>
            <GatsbyImage
              image={thumbnailImage}
              alt=""
              className="card-img-top mb-3"
            />
          </Link>
        </Col>

        <Col md={horizontalCard ? 5 : 12}>
          <Card.Body className={classes.body}>
            <Card.Subtitle as="h5" className="mb-4">
              {tagList}
            </Card.Subtitle>
            <Card.Title as="h4" className="mb-4">
              {title}
            </Card.Title>
            <Card.Text className="mb-0">{excerpt}</Card.Text>
          </Card.Body>

          <div className={classes.cta}>
            <Link to={link}>
              <Button variant="dark">Read the article</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default PostCard
