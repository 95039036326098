import { graphql, useStaticQuery } from "gatsby"
import intersection from "lodash.intersection"
import React from "react"
import useBreakpoint from "use-breakpoint"
import config from "../../config"
import { DataPostsResult } from "../../contracts"
import { convertPostDataToPost } from "../../utils"
import PostList from "./PostList"

export interface RelatedPostsProps {
  /**
   * List of post tags to fetch results for.
   */
  tags: string[]

  /**
   * List of post IDs to exclude from the results.
   */
  excludedPosts?: string[]
}

const RelatedPosts = ({ tags, excludedPosts }: RelatedPostsProps) => {
  const { minWidth } = useBreakpoint(config.bootstrapBreakpoints, "xs")

  // Select and filter all related posts at build time.
  const data = useStaticQuery<DataPostsResult>(graphql`
    query RelatedPostsQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { template: { eq: "ParentingPost" } } }
        limit: 1000
      ) {
        edges {
          node {
            id
            fields {
              path
            }
            frontmatter {
              title
              date
              description
              tags
              thumbnail {
                childImageSharp {
                  gatsbyImageData(width: 375, height: 375)
                }
              }
            }
          }
        }
      }
    }
  `)

  let rawPosts = data.allMarkdownRemark.edges.map(edge => edge.node)

  // Only include posts that share a tag.
  rawPosts = rawPosts.filter(
    post => intersection(tags, post.frontmatter.tags).length > 0
  )

  if (excludedPosts) {
    rawPosts = rawPosts.filter(post => !excludedPosts.includes(post.id))
  }

  const posts = rawPosts.map(convertPostDataToPost)

  // Smaller breakpoints show 2 posts to avoid a row of 2 posts, and a row of 1 post.
  const numPosts =
    typeof minWidth !== "undefined" && minWidth < config.bootstrapBreakpoints.xl
      ? 2
      : 3
  const relatedPosts = posts.slice(0, numPosts)

  return <PostList posts={relatedPosts} />
}

export default RelatedPosts
