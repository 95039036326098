import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { DataPostSection } from "../../contracts"
import { markdownToHtml } from "../../utils/markdown-to-html"
import ContentSection from "./ContentSection"
import PromoSection from "./PromoSection"
import PullOutSection from "./PullOutSection"

export interface SectionBuilderProps {
  /**
   * Section data from netlify-cms.
   */
  section: DataPostSection
}

/**
 * Convert a netlify-cms "section" used to compose pages/posts into an appropriate
 * React component.
 */
const SectionBuilder = ({ section }: SectionBuilderProps) => {
  if (section.type === "content") {
    const html = markdownToHtml(section.body)
    return <ContentSection html={html} />
  } else if (section.type === "pull-out") {
    const html = markdownToHtml(section.body)
    const image = getImage(section.image)

    if (!image) {
      return null
    }

    return <PullOutSection html={html} image={image} />
  } else if (section.type === "promo") {
    const html = markdownToHtml(section.body)

    return <PromoSection type={section.promoType} html={html} />
  }

  throw new Error(
    `Unsupported section type: ${(section as DataPostSection).type}`
  )
}

export const query = graphql`
  fragment ContentSection on Section {
    type
    body
  }

  fragment PullOutSection on Section {
    type
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }

  fragment PromoSection on Section {
    type
    promoType: promo_type
    body
  }
`

export default SectionBuilder
